import { template as template_a01839e73db740f1861156be3a34e4f3 } from "@ember/template-compiler";
const SidebarSectionMessage = template_a01839e73db740f1861156be3a34e4f3(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
