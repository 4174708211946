import { template as template_127c9c78ec2444c4a0013e57684a1f0b } from "@ember/template-compiler";
const EmptyState = template_127c9c78ec2444c4a0013e57684a1f0b(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
