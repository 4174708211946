import { template as template_a73c8367f57741db860bede9d5b20253 } from "@ember/template-compiler";
const FKText = template_a73c8367f57741db860bede9d5b20253(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
