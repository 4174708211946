import { template as template_38b0827095b246ae8832b1f73be43117 } from "@ember/template-compiler";
const FKControlMenuContainer = template_38b0827095b246ae8832b1f73be43117(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
