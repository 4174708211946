import { template as template_24217e10fbd048039f037f46c986adbf } from "@ember/template-compiler";
const WelcomeHeader = template_24217e10fbd048039f037f46c986adbf(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
