import { template as template_fefdb56917614edabc0a6f0d9cfd69e0 } from "@ember/template-compiler";
const FKLabel = template_fefdb56917614edabc0a6f0d9cfd69e0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
